import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
} from '@mui/material';
import { ApplyCampaignService, LocalService, SubscriptionService } from '../../../../../../../core/services';
import { useSearchParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Profile } from '../proposal-components/profile';
import { DeliverableTool } from '../proposal-components/deliverable-tool';
import Loading from '../../../../../../../components/loader/loading';
import ProposalContainer from '../proposal-components/proposal-container';
import ProposalActionBtn from '../proposal-components/proposal-action-btn';
import { createPortal } from 'react-dom';
import ConfirmPopup from '../../../../../../../components/confirm-popup/confirm-popup';
// important common popup component
import CommonPopUp from '../../../../../../../components/popup/common-popup';
// For brand foc campaign payment
import useRazorpay from 'react-razorpay';
import { makeRazorpayPayment } from '../../../../../../../core/services/PaymentService';
import UpgradeBrandPopUp from '../../../../../../../components/popup/upgrade-brand-popup';
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { BASE_URL_WEB } from '../../../../../../../config/config';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { Checkbox } from "@mui/material";

const applyCampaignService: ApplyCampaignService = new ApplyCampaignService();
const subscriptionService: SubscriptionService = new SubscriptionService();
const  localService:LocalService = new LocalService();


interface Props {
  UserReducer: any;
  selectedCampaign: any;
  unreadProposal: any;
  updateUnreadProposal: () => void;
  set_sm_id?: (id: number) => void;
  getSubscriptionDetail: any;
}

type StatusType = 'Pending' | 'Negotiating' | 'Accepted' | 'Rejected' | 'Shortlisted' | 'NonNegotiable';

type CurrentSteps = 1 | 2 | 3 | 4;

interface Influencer {
  influencer_id: number;
  username: string;
  sm_id: number;
  channel_id: string;
  avg_views: number;
}

interface FormDataType {
  negotiation_id: number;
  proposal_id: number;
  campaign_id: number;
  influencer_id: number;
  sm_id: number;
  deliverable_item: string;
  s_0_quantity: number;
  s_0_price: number;
  s_0_status: StatusType;
  s_0_date: string;
  s_1_quantity: number;
  s_1_price: number;
  s_1_status: StatusType;
  s_1_date: string;
  s_2_quantity: number;
  s_2_price: number;
  s_2_status: StatusType;
  s_2_date: string;
  s_3_quantity: number;
  s_3_price: number;
  s_3_status: StatusType;
  s_3_date: string;
  current_step: CurrentSteps;
  status: StatusType;
  proposal_datetime: string;
  created_at: string;
  updated_at: string;
}

const BrandReceived: React.FC<Props> = (props: Props) => {
  const [influencers, setInfluencers] = useState<Influencer[]>([]);
  const [originalInfluencers, setOriginalInfluencers] = useState<Influencer[]>([]);
  const [filteredInfluencers, setFilteredInfluencers] = useState<Influencer[]>([]);
  const [expanded, setExpanded] = useState<number | false>(false);
  const [details, setDetails] = useState<FormDataType[]>([]);
  const [searchParams] = useSearchParams();
  const [negotiateVisible, setNegotiateVisible] = useState(false);
  const [selectedProposals, setSelectedProposals] = useState<any[]>([]);
  const [selectProposalAll, setSelectProposalAll] = useState<boolean>(false);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [deliverableHeader, setDeliverableHeader] = useState<any>([]);
  const [openRejectPopup, setOpenRejectPopup] = useState(false);
  const [openAcceptPopup, setOpenAcceptPopup] = useState(false);
  const [openAcceptAllPopup, setOpenAcceptAllPopup] = useState(false);
  const [openRejectAllPopup, setOpenRejectAllPopup] = useState(false);
  const [openShortlistAllPopup, setOpenShortlistAllPopup] = useState(false);

  const [openShortListPopup, setOpenShortListPopup] = useState(false);

  const [campaignType] = useState(props.selectedCampaign?.campaign_type || "Paid");

  // Foc payment related
  // FOc campaign logic
  const [razorpay] = useRazorpay();

  // For foc popup terms and condition checkbox
  const [isChecked, setChecked] = useState(true);
  const [isTcErrorVisible, setTcErrorVisible] = useState(false);
  // set foc popup and gstin popup state
  const [popUps, setPopUps] = useState({
    openGSTINModal: false,
    openFocModal: false,
    openUpgradeBrandModal: false
  });

  // To handle Foc modal popup open and close
  const handleOpenFocModal = () => setPopUps({ ...popUps, openFocModal: true });

  const [availablePlan, setAvailablePlan] = useState<any>(null);

  // enum set for modal content
  const ModalContent = {
    UPGRADE_BARTER_PROPOSAL_LIMIT: ''
  };

  // enum set for modal title
  const ModalTitle = {
    UPGRADE_BARTER_PROPOSAL_LIMIT: 'Update Proposal Limit'
  };

  // Common modal content and title useState
  const [modalContent, setModalContent] = useState(ModalContent.UPGRADE_BARTER_PROPOSAL_LIMIT);
  const [modalTitle, setModalTitle] = useState(ModalTitle.UPGRADE_BARTER_PROPOSAL_LIMIT);

  // To handle Foc terms and condition checkbox
  const handleCheckboxChange = () => {
    setChecked(!isChecked);
    setTcErrorVisible(false); // Reset error message when the checkbox state changes
  };

  const [influencersData, setInfluencersData] = useState({
    Nano: [],
    Micro: [],
    'Micro Plus': [],
    Macro: [],
    'Macro Plus': [],
    Mega: [],
    'Mega Plus': [],
    All: [],
  });

  const campaignDetails = useMemo(() => {
    return {
      campaignId: searchParams.get('campaignID'),
      planId: searchParams.get('planID'),
    };
  }, [searchParams]);

  const getDeliverableHeader = async () => {
    try {
      const response: any = await ApplyCampaignService.getDeliverableHeader({ campaign_id: campaignDetails.campaignId });
      if (response.status === 1) {
        setDeliverableHeader(response.data.rows);
      }
    } catch (error) {
      console.error('Error fetching deliverable header:', error);
    }
  };

  const getCommonStatus = (details: any[], campaign_cost_model: string): string => {
    if (details.length === 0) return "Negotiating";
    const statusIndexKey = `s_${details[0].current_step}_status`;
    if (campaign_cost_model === "Individual") {
      const commonStatus = details[0]?.[statusIndexKey];
      return details.every(obj => obj?.[statusIndexKey] === commonStatus) ? commonStatus : "Negotiating";
    }
    else {
      const package_cost_item = details.filter(obj => obj?.deliverable_item === "Package Cost 1");
      return package_cost_item[0][statusIndexKey]
    }
  }


  const submitProposal = async () => {
    try {
      setLoading(true);
      const status_value = getCommonStatus(details, props.selectedCampaign.campaign_cost_model);
      const response = await applyCampaignService.proposalNegotiate({ proposalNegotiate: details, status_value: status_value, user_type: "Brand" })
      if (response?.data?.status.status == true) {
        setLoading(false);
        navigate(`?planID=${campaignDetails.planId}&campaignID=${campaignDetails.campaignId}`);
        getInfluencerList()
      }

    }
    catch (error) {
      console.error("Error in Negotiation ")
    }
  }
  const shortListProposal = async (status: string) => {

    try {
      setLoading(true);
      const response = await applyCampaignService.shortListProposal({
        status: status,
        proposal_id: details[0].proposal_id,
      })
      if (response) {
        setExpanded(false);
        navigate(`?planID=${campaignDetails.planId}&campaignID=${campaignDetails.campaignId}`);
        getInfluencerList()


      }
    } catch (error) {
    }
  }

  const AcceptProposal = async (status: string) => {
    try {
      setLoading(true);
      const res: any = await applyCampaignService.acceptProposal({
        status: status,
        proposal_id: details[0].proposal_id,
        user_type: "Brand",
      })
      if (res.data.status.status == true) {
        setLoading(false);
        setExpanded(false);
        navigate(`?planID=${campaignDetails.planId}&campaignID=${campaignDetails.campaignId}`);
        getInfluencerList()

      }
    } catch (error) {
      setLoading(false);
      console.warn("error", error)

    }
  }
  const RejectProposal = async (status: string) => {
    try {
      setLoading(true);
      const res: any = await applyCampaignService.rejectProposal({
        status: status,
        proposal_id: details[0].proposal_id,
        user_type: "Brand",
      })
      if (res.data.status.status == true) {
        setLoading(false);
        setExpanded(false);
        navigate(`?planID=${campaignDetails.planId}&campaignID=${campaignDetails.campaignId}`);
        getInfluencerList()
      }
    } catch (error) {
      setLoading(false);
      console.warn("error", error)


    }
  }
  const getInfluencerList = async () => {
    try {
      setLoading(true);
      const response = await ApplyCampaignService.influencerList({ campaign_id: campaignDetails.campaignId, proposalStatus: 'Pending' });
      if (response?.data?.rows?.length >= 0) {
        let inf: any = {
          Nano: [],
          Micro: [],
          'Micro Plus': [],
          Macro: [],
          'Macro Plus': [],
          Mega: [],
          'Mega Plus': [],
          All: response.data.rows,
        };

        response.data.rows.forEach((res: any) => {
          if (inf[res.infType]) {
            inf[res.infType].push(res);
          }
        });
        setInfluencersData(inf);
        setInfluencers(response.data.rows);

        setOriginalInfluencers(response.data.rows);
        setFilteredInfluencers(response.data.rows);
      }
    } catch (error) {
      console.error('Error fetching influencer list:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getInfluencerList();
    getDeliverableHeader();
    // Checking for FOC Republish campaign
    if (['barter', 'Barter'].includes(props.selectedCampaign.campaign_type)) {
      getFocCampaignPlanDetails('Upgrade Barter Proposal Limit');
      setModalContent(ModalContent.UPGRADE_BARTER_PROPOSAL_LIMIT);
      setModalTitle(ModalTitle.UPGRADE_BARTER_PROPOSAL_LIMIT);
    }
  }, [campaignDetails.campaignId]);

  const handleChange = async (panel: number, sm_id: number, foc: boolean) => {
    try {
      setLoading(true);
      if (foc && props.selectedCampaign.campaign_type.toLowerCase().trim() != 'barter') {
        handleOpenUpgradeBrandModal(true)
        return;
      }
      else if (foc && props.selectedCampaign.campaign_type.toLowerCase().trim() === 'barter') {
        handleOpenFocModal();
        return;
      }
      if (expanded === panel) {
        setExpanded(false);
      }
      else {
        const response = await ApplyCampaignService.brandProposalDetails({ smId: sm_id, campaign_id: campaignDetails.campaignId });
        if (response?.data?.rows?.length > 0) {
          setDetails(response.data.rows);
          setExpanded(panel);
          getInfluencerList();
        }
      }

    } catch (error) {
      console.error('Error fetching proposal details:', error);
    } finally {
      if (props.unreadProposal.some((e: any) => e.sm_id === sm_id)) {
        props.updateUnreadProposal();
      }
      setLoading(false);
    }
  };

  function handleNegoValue(event: FormDataType, index: number) {
    setDetails((prev: any[]) => {
      return prev.map(item =>
        item.deliverable_item === event.deliverable_item ? { ...item, ...event } : item
      );
    });

  };


  const handleAcceptAllClose = () => {
    setOpenAcceptAllPopup(false);
    document
      .getElementsByTagName('body')[0]
      .classList.remove('custom-drawer-active');
  };

  const handleAcceptAllConfirm = () => {
    setOpenAcceptAllPopup(true);
    document
      .getElementsByTagName('body')[0]
      .classList.add('custom-drawer-active');
  }

  const handleRejectAllClose = () => {
    setOpenRejectAllPopup(false);
    document
      .getElementsByTagName('body')[0]
      .classList.remove('custom-drawer-active');
  };
  const handleRejectAllConfirm = () => {
    setOpenRejectAllPopup(true);
    document
      .getElementsByTagName('body')[0]
      .classList.add('custom-drawer-active');
  }
  const handleShortlistAllClose = () => {
    setOpenShortlistAllPopup(false);
    document
      .getElementsByTagName('body')[0]
      .classList.remove('custom-drawer-active');
  };
  const handleShortlistAllConfirm = () => {
    setOpenShortlistAllPopup(true);
    document
      .getElementsByTagName('body')[0]
      .classList.add('custom-drawer-active');
  }
  const handleAcceptClose = () => {
    setOpenAcceptPopup(false);
    document
      .getElementsByTagName('body')[0]
      .classList.remove('custom-drawer-active');
  };
  const handleAcceptConfirm = () => {
    setOpenAcceptPopup(true);
    document
      .getElementsByTagName('body')[0]
      .classList.add('custom-drawer-active');
  }

  const handleRejectClose = () => {
    setOpenRejectPopup(false);
    document
      .getElementsByTagName('body')[0]
      .classList.remove('custom-drawer-active');
  };
  const handleRejectConfirm = () => {
    setOpenRejectPopup(true);
    document
      .getElementsByTagName('body')[0]
      .classList.add('custom-drawer-active');
  }

  const handleShortListConfirm = () => {
    setOpenShortListPopup(true);
    document
      .getElementsByTagName('body')[0]
      .classList.add('custom-drawer-active');
  }
  const handleShortListClose = () => {
    setOpenShortListPopup(false);
    document
      .getElementsByTagName('body')[0]
      .classList.remove('custom-drawer-active');
  };

  const handleFilteredInfluencer = (event: any) => {
    const value = event.toLowerCase();
    if (value === 'all') {
      setFilteredInfluencers(originalInfluencers);
    } else {
      const filtered = originalInfluencers.filter((influencer: any) =>
        influencer?.infType?.toLowerCase() === value?.toLowerCase()
      );
      setFilteredInfluencers(filtered);
    }
  };

  const handleOpenUpgradeBrandModal = (value: boolean) => {
    setPopUps({ ...popUps, openUpgradeBrandModal: true })
  };

  function handleClosePopUps() {
    setPopUps({
      openGSTINModal: false,
      openFocModal: false,
      openUpgradeBrandModal: false
    })
  }

  // For foc new campaign plan
  async function getFocCampaignPlanDetails(plan_name: string) {
    const { user_id, account_id, account_type, influencer_id } = props.UserReducer;
    const response = await subscriptionService.getFocCampaignPlanDetail({
      user_id: user_id,
      account_id: account_id,
      user_type: account_type,
      influencer_id: influencer_id,
      plan_name: plan_name
    });
    if (response.data.status.status) {
      //return Only Active Plan Info
      setAvailablePlan(response.data.rows);
    } else {
      // localService.toastify(response.data.status.message, 'info', 1000);
      setAvailablePlan(null);
    }
  }

  // For FOC campaign payment
  async function makePayment(data: any) {
    // let paymantStatus = {};
    setLoading(true);
    try {
      if (!isChecked) {
        setTcErrorVisible(true); // Show error if the checkbox is not checked
      }
      else {
        const paymentResponse = await makeRazorpayPayment(data, props, razorpay);
        if (paymentResponse?.status) {
          navigate(`/brand/orders/confirmed?payment_id=${paymentResponse?.paymentId}&campaign_id=${props.selectedCampaign.campaign_id}&campaign_planId=${props.selectedCampaign.plan_id}`);
          handleClosePopUps();
        } else {
          // Payment failed, handle it
          console.log("Payment failed");
          // localService.toastify(paymentResponse?.message, "error", 1000);
        }
      }
    } catch (error) {
      console.error(`Make Payment Error :: ${JSON.stringify(error)}`);
    } finally {
      setLoading(false);
    }
  }

  const handleCheckbox = (inf: any) => {
    setLoading(true);
      if (inf.foc == "true" && props.selectedCampaign.campaign_type.toLowerCase().trim() != 'barter') {
        handleOpenUpgradeBrandModal(true)
        return;
      }
      else if (inf.foc == "true" && props.selectedCampaign.campaign_type.toLowerCase().trim() === 'barter') {
        handleOpenFocModal();
        return;
      }
      else{
        setSelectedProposals((prev) =>
          prev.includes(inf.proposal_id)
            ? prev.filter((id) => id !== inf.proposal_id)
            : [...prev, inf.proposal_id]
        );
      }

  };

  const handleCheckboxSelectAll = (data: boolean) => {
    if (data) {
      const newSelectedProposals = filteredInfluencers
        .filter((inf: any) => (inf.foc != "true")) // Use === for strict comparison
        .map((inf: any) => inf.proposal_id)        // Extract proposal_ids
        .filter((proposal_id: any) => !selectedProposals.includes(proposal_id)); // Exclude already selected proposals
  
      setSelectedProposals(prev => [...prev, ...newSelectedProposals]); // Add new proposals
    } else {
      setSelectedProposals([]);
    }
    
    setSelectProposalAll(data);
  };

  const UpdateBulkProposal = async (status: string) => {
    try {
      setLoading(true);
      const res:any = await applyCampaignService.updateBulkProposals({
        proposal_status: status,
        proposal_ids: selectedProposals,
      })
      if(res.data.status.status == true){
        setLoading(false);
        setExpanded(false);
        navigate(`?planID=${campaignDetails.planId}&campaignID=${campaignDetails.campaignId}`);
        getInfluencerList();
        setSelectedProposals([]);

      }
      else{
        localService.toastify(res.data.status.message,"error",5000)
      }
    } catch (error) {
      setLoading(false);
      console.warn("error",error)

    }
  }
  return (

    <div className="proposal-container">
      {/* PROPOSAL CONTAINER LEFT */}
      <div className="proposal-container-left">
        <DeliverableTool data={influencersData} filteredInfluencer={(influencer: any) => handleFilteredInfluencer(influencer)} />
      </div>

      {/* PROPOSAL CONTAINER RIGHT */}
      {
        filteredInfluencers.length > 0 &&
      <div className="proposal-container-right">
        <div style={{display:'flex' }}>
              <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '42px' }}>
                <FormControlLabel control={<Checkbox
                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                  checkedIcon={<CheckBox fontSize="small" />}
                  checked={selectProposalAll || filteredInfluencers.length == selectedProposals.length}
                  onChange={() => handleCheckboxSelectAll(!selectProposalAll)}
                />} label="Select All" />
              {/* <span style={{ fontSize: '14px' }}>Select All</span> */}
          </div>
            <div style={{ padding:'10px 0px 10px 10px', marginLeft:'auto', display:'flex', gap:'8px'}}>
            <button className={`btn btn-sm btn-outline-primary ${selectedProposals.length <= 0 ? 'disabled' : ''}`} onClick={handleShortlistAllConfirm}>Shortlist</button>
            <button className={`btn btn-sm btn-primary ${selectedProposals.length <= 0 ? 'disabled' : ''}`} onClick={handleAcceptAllConfirm}>Accept</button>
            <button className={`btn btn-sm btn-outline-error ${selectedProposals.length <= 0 ? 'disabled' : ''}`} onClick={handleRejectAllConfirm}>Reject</button>
          </div>
        </div>
        {filteredInfluencers.sort((a: any, b: any) => {
          const dateA = new Date(a.proposal_datetime).getTime();
          const dateB = new Date(b.proposal_datetime).getTime();
          return dateB - dateA;
        }).map((inf: any, index: any) => (
          <Accordion className='accordion-container' key={inf.influencer_id} expanded={expanded === index} onChange={() => handleChange(index, inf.sm_id, (inf?.foc === 'true' ? true : false))}>
            <AccordionSummary>
              <div className="proposal-header">
                {props.unreadProposal.length > 0 && (props.unreadProposal.map((influencer_data: any, index: any) => (
                  (inf.sm_id === influencer_data.sm_id && influencer_data.status === 'Pending' && influencer_data.read == 'no') &&

                  <span className='unread-notification-icon'></span>
                  
                )))}
                 <Checkbox
                    icon={<CheckBoxOutlineBlank fontSize="small" />}
                    checkedIcon={<CheckBox fontSize="small" />} 
                    style={{ padding: '16px' }}
                    checked={selectedProposals.includes(inf.proposal_id)}
                    onClick={(e) => e.stopPropagation()} 
                    onChange={() => handleCheckbox(inf)}
                  />
                <div className="proposal-header-left">
                  <Profile profileData={inf} userReducer={props.UserReducer} />
                </div>
                <div className="proposal-header-right">
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              {loading && expanded === index && details ? (
                <Loading width='80' height='80' />
              ) : (
                <>
                  <ProposalContainer deliverableHeader={deliverableHeader}
                    data={details}
                    step={details}
                    index={index}
                    profileData={inf}
                    negotiateVisible={negotiateVisible}
                    onChange={(event: any) => handleNegoValue(event, index)}
                    setNegotiateVisible={setNegotiateVisible}
                    submitProposal={submitProposal}
                    campaignType={campaignType}

                  />
                </>
              )}
            </AccordionDetails>
            {!negotiateVisible && (details && details[0]?.current_step == 1 || details[0]?.current_step == 3) &&
              <>
                <ProposalActionBtn setNegotiateVisible={setNegotiateVisible} data={details} handleShortListConfirm={handleShortListConfirm} handleAcceptConfirm={handleAcceptConfirm} handleRejectConfirm={handleRejectConfirm} campaignType={campaignType} />
              </>
            }
          </Accordion>
        ))
        }
        {/* DISPLAY EMPTY MESSAGE */}
        {influencers.length === 0 && (
          <p className="no-list-comment align-items-baseline mt-5">There are no received proposals at the moment.</p>
        )}
        <>
          {openRejectPopup &&
            <>{createPortal(<ConfirmPopup confirmPopupInnerText="Are you sure want to reject the proposal?" openPopup={openRejectPopup} confirmClosePopup={handleRejectClose} confirmClickAction={(() => {
              RejectProposal("Rejected")
            })} drawerTitle="" />, document.body)}</>
          }

          {openShortListPopup &&
            <>{createPortal(<ConfirmPopup confirmPopupInnerText="Are you sure want to shortlist the proposal?" openPopup={openShortListPopup} confirmClosePopup={handleShortListClose} confirmClickAction={(() => {
              shortListProposal("Shortlisted")
            })} drawerTitle="" />, document.body)}</>}
          {openAcceptPopup && <>
            {createPortal(<ConfirmPopup confirmPopupInnerText="Are you sure want to accept the proposal?" openPopup={openAcceptPopup} confirmClosePopup={handleAcceptClose} confirmClickAction={(() => {
              AcceptProposal("Accepted")
            })} drawerTitle="" />, document.body)}
          </>}

          {
          openAcceptAllPopup && 
          <>
            {createPortal(<ConfirmPopup confirmPopupInnerText= {selectedProposals.length > 1 ? "Are you sure want to accept all the proposals?" : "Are you sure want to accept the proposal?"} openPopup={openAcceptAllPopup} confirmClosePopup={handleAcceptAllClose} confirmClickAction={(() => {
              UpdateBulkProposal("Accepted")
            })} drawerTitle="" />, document.body)}
          </>
          }
          {
          openRejectAllPopup && 
          <>
            {createPortal(<ConfirmPopup confirmPopupInnerText={selectedProposals.length > 1 ? "Are you sure want to reject all the proposals?" : "Are you sure want to reject the proposal?"} openPopup={openRejectAllPopup} confirmClosePopup={handleRejectAllClose} confirmClickAction={(() => {
              UpdateBulkProposal("Rejected")
            })} drawerTitle="" />, document.body)}
          </>
          }
          {openShortlistAllPopup &&
            <>{createPortal(<ConfirmPopup confirmPopupInnerText = {selectedProposals.length > 1 ? "Are you sure want to shortlist all the proposals?" : "Are you sure want to shortlist the proposal?"} openPopup={openShortlistAllPopup} confirmClosePopup={handleShortlistAllClose} confirmClickAction={(() => {
              UpdateBulkProposal("Shortlisted")
            })} drawerTitle="" />, document.body)}</>}
          

        </>
      </div>
      }
      {popUps.openUpgradeBrandModal && (
        <UpgradeBrandPopUp
          openUpgradeBrandModal={popUps.openUpgradeBrandModal}
          onClose={() => handleClosePopUps()}
          popupMinWidth='630px'
        >
        </UpgradeBrandPopUp>
      )}
      {popUps.openFocModal && (
        <CommonPopUp
          modalTitle={modalTitle}
          modalContent={modalContent}
          openModal={popUps.openFocModal}
          onClose={() => handleClosePopUps()}
          popupMaxWidth='600px'
        >
          <div className="justify-content-between align-items-center mt-1">
            <div className="">
              <p className="inline mt-0">
                {`To view more proposals, please pay Rs.${availablePlan[0]?.amount} or view our `}
                <a className="btn btn-text-link" target="_blank" href="/brand/settings/subscription">
                  Pricing plans
                </a>{' '}
                for unlimited access.
              </p>
              <p className="inline mt-0">
                {`To view more proposals, a payment of Rs.${availablePlan[0]?.amount} is applicable. This will allow you to view 10 additional proposals.
                For unlimited access, please check out our `}
                <a className="btn btn-text-link" target="_blank" href="/brand/settings/subscription">
                  pricing plans
                </a>.
              </p>
              <div className="form-check">
                <input
                  checked={isChecked}
                  className="form-check-input mt-1"
                  id="defaultCheck1"
                  type="checkbox"
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label" htmlFor="defaultCheck1">
                  I agree to{' '}
                  <a className="btn btn-text-link" target="_blank" href={`${BASE_URL_WEB}/terms_conditions`}>
                    Terms &amp; Conditions
                  </a>
                </label>
                <small className={`text-red ${isTcErrorVisible ? '' : 'd-none'}`}>This field is required.</small>
              </div>
            </div>
            <div className='popup-footer mt-4'>
              <div className='row'>
                <div className='col text-end'>
                  {availablePlan && (
                    <>
                      {availablePlan.map((data: any, index: number) => {
                        return (
                          <LoadingButton
                            className='btn btn-primary buy-btn'
                            loading={loading}
                            loadingPosition='start'
                            variant='contained'
                            onClick={() => makePayment(data)}
                          >
                            Pay Rs. {data.amount}
                          </LoadingButton>
                        );
                      })}
                    </>
                  )}

                </div>
              </div>
            </div>
          </div>
        </CommonPopUp>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer.UserData,
    UserID: state.UserID.userID,
    selectedCampaign: state.InfSelectCamp.campaign,
    getSubscriptionDetail: state.Subscription.subscription_detail,
  };
};

const connectToRedux = connect(mapStateToProps)(BrandReceived);

export { connectToRedux as BrandReceived };
