import React, { useState, useEffect } from 'react';
import {
  useSearchParams,
  NavLink,
  Outlet,
  useNavigate
} from 'react-router-dom';
import {
  Loading,
  SelectedCampaign,
} from '../../../../../redux/action';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import InstagramIcon from '../../../../../components/icons/instagram-icon';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { FormControl, InputLabel, Select, MenuItem, Tooltip } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

import IconCampaignDetail from '../../../../../components/icons/icon-campaign-detail';
import IconProposal from '../../../../../components/icons/icon-proposal';
import IconCreative from '../../../../../components/icons/icon-creative';
import IconPayment from '../../../../../components/icons/icon-payments';
import IconAnalytics from '../../../../../components/icons/icon-analytics';
import PlanDetailDrawer from '../../../../../components/plan-detail-drawer/plan-detail-drawer';
import { ListService, LocalService, ApplyCampaignService } from '../../../../../core/services';
import eventEmitter from '../../../../../core/services/event-emitter';

import './campaign-view.scss';
import { MenuPropsSelect } from '../../../../../core/utility/style';
import MaterialSymbol from '../../../../../components/icons/material-symbol';

const listService: ListService = new ListService();
const localService = new LocalService();


interface Props {
  UserReducer: any;
  selectInfluencer: (data: any) => void;
  loading: (data: any) => void;
}

const CampaignView: React.FC<Props> = (props: Props) => {
  const [campaignList, setCampaignList] = useState<any>(null);
  const [selectedCampaign, setSelectedCampaign] = useState<any>(null);
  const [activeCampaign, setActiveCampaign] = useState<any>(null);
  const [campaignDetailMenu, setCampaignDetailMenu] = useState(0);
  const [openPlanDetailPopup, setOpenPlanDetailPopup] = useState(false);
  const [plan_id, setPlan_id] = useState<any>(null);
  const [planName, setPlanName] = useState<any>(null);
  const [planInfo, setPlanInfo] = useState({
    industry: props.UserReducer.brand_category,
    category: props.UserReducer.brand_category,
    organization: props.UserReducer.brand_organization,
    brand: props.UserReducer.brand_name,
    description: props.UserReducer.description,
  });
  const [activeCampaignDetailMenu, setActiveCampaignDetailMenu] = useState(() => {
    const savedMenu = localStorage.getItem('activeCampaignDetailMenu');
    return savedMenu !== null ? savedMenu : 'Campaign Details';
  });

  const [unreadProposal, setUnreadProposal] = useState([]);
  const [unreadCreative, setUnreadCreative] = useState([]);
  const [selection, setSelection] = useState();
  const navigate = useNavigate();

  const searchParams = useSearchParams();
  useEffect(() => {
    const getPlan_id = searchParams[0].get('planID');
    setPlan_id(getPlan_id)
    if (getPlan_id) {
      props.loading(true);
      listService.plan_campaigns({
        brand_id: props.UserReducer.brand_id,
        user_id: props.UserReducer.user_id,
        plan_id: getPlan_id,
      })
        .then((res: any) => {
          if (res.data?.status.status) {
            setPlanName(res.data?.rows[0]?.plan_name);
            setCampaignList(res.data.rows);
            res.data.rows.filter((data: any, index: number) => {
              if (data.campaign_id === parseInt(searchParams[0].get('campaignID') ?? '')) {
                setActiveCampaign(index);
                setSelectedCampaign(data);
                handleCampaign(res.data.rows[index], index);
              }
            })
            // localService.toastify(res.data.status.message, 'success');
          } else {
            localService.toastify(res.data?.status.message, 'info');
          }

          props.loading(false);
        })
        .catch((err) => {
          console.warn(err);
          props.loading(false);
        });
    }

  }, [props.UserReducer, selection, plan_id]);

  useEffect(() => {
    localStorage.setItem('activeCampaignDetailMenu', activeCampaignDetailMenu);
  }, [activeCampaignDetailMenu]);


  useEffect(()=>{

      getUnreadProposalsCreatives();

    eventEmitter.on('callUnreadProposalsCreatives', getUnreadProposalsCreatives);

    // Cleanup event listener on component unmount
    return () => {
      eventEmitter.off('callUnreadProposalsCreatives', getUnreadProposalsCreatives);
    };

  }, [searchParams[0].get('campaignID')])

  const getUnreadProposalsCreatives = async () => {
    const getSearchParams = searchParams[0].get('campaignID');
    if (getSearchParams) {
      ApplyCampaignService.getUnreadProposalCreative({
        user_id: props.UserReducer.user_id,
        account_id: props.UserReducer.account_id,
        campaign_id: getSearchParams,
      })
        .then((res: any) => {
          if (res.data.status.status) {
            setUnreadProposal(res.data.rows.unread_proposals);
            setUnreadCreative(res.data.rows.unread_creatives);
          }
        })
        .catch((error) => {
          setUnreadProposal([]);
          setUnreadCreative([]);
        }).finally(() => { });
    }
  }


  const handleCampaign = (data: any, index: number) => {
    setSelectedCampaign(data);
    props.selectInfluencer({ campaign: data });
    setCampaignDetailMenu(1);
  };

  const handleTabClick = (tabName: any, route: any) => {
    setActiveCampaignDetailMenu(tabName);
    navigate(route);
  };

  const handlePlanListChange = (event: any) => {
    const index = event.target.value;
    setActiveCampaign(index);
    const selectedCampaign = campaignList[index];
    handleCampaign(selectedCampaign, 0)
    setActiveCampaignDetailMenu('Campaign Details');
    const route = `/brand/campaigns/campaign-details?planID=${plan_id}&campaignID=${selectedCampaign.campaign_id}`;
    navigate(route);
  };
  const handlePlanDetailPopup = (data: any) => {
    setOpenPlanDetailPopup(true);
  };
  const handlePlanDetailPopupClose = (id: any) => {
    setOpenPlanDetailPopup(false);
  };

  return (
    <>
      <section className='creative-w-sidebar campaign-proposal'>
        <div className='sidebar-content-sec'>
          <div className='plan-list-sec'>
            <div className='plan-list-left'>
              <div className='plan-name'>
                <span className='plan-name-heading'>Plan</span>
                <div className='plan-details'>
                  <div className='scrolling-text-sec'>
                    <div className='inner-scrolling-text-container'>
                      <strong className="inner-scrolling-text">{planName}</strong>
                    </div>
                  </div>
                  <span className='cursor-pointer' onClick={handlePlanDetailPopup}>View Plan Details</span>

                </div>
              </div>
            </div>
            <div className='plan-list-right'>
              <div className='plan-list'>
                {campaignList && (
                  <FormControl className='plan-list-dropdown'>
                    <InputLabel id='plan-list-dropdown-label'>Campaign</InputLabel>
                    <Select
                      labelId='plan-list-dropdown-label'
                      id='plan-list-dropdown'
                      value={activeCampaign}
                      label='Campaign'
                      onChange={handlePlanListChange}
                      IconComponent={(props) => (<ExpandMore {...props} />)}
                      MenuProps={MenuPropsSelect}
                    >
                      {campaignList.map((res: any, index: number) => (
                        <MenuItem value={index} key={index}>
                          <div className='d-flex scrolling-text-sec w-100'>
                            <span className='camp-platform-type-icon d-inline-flex'>
                              {res.campaign_platform === 'Instagram' ? (
                                <InstagramIcon />
                              ) : (
                                <YouTubeIcon />
                              )}
                              <span className='ps-1'>{res.campaign_type}</span>
                            </span>
                            {res.campaign_title.length > 20 ? (
                              <div className='inner-scrolling-text-container'>
                                <span

                                  className="inner-scrolling-text camp-title"
                                >
                                  {res.campaign_title}
                                </span>
                              </div>

                            ) : <span

                              className="camp-title"
                            >
                              {res.campaign_title}
                            </span>}

                          </div>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </div>
              <NavLink to="/brand/plans" className={props.UserReducer.role_name != null && props.UserReducer.create_campaign === "False" ? "btn btn-rounded btn-outline-primary plan-list-btn btn-mui disabled" : "btn btn-rounded btn-outline-primary plan-list-btn btn-mui"}>
                <Tooltip title="Create Campaign" arrow>
                  <span>
                    +
                  </span>
                </Tooltip>
              </NavLink>
            </div>
          </div>
          {campaignList && (
            <div className='col'>
              <nav className='sidebar-nav'>
                <span className={activeCampaignDetailMenu === 'Campaign Details' ? "sidebar-nav-link active" : "sidebar-nav-link"} onClick={() =>
                  handleTabClick('Campaign Details', `campaign-details?planID=${plan_id}&campaignID=${selectedCampaign.campaign_id}`)
                }>
                  <span className='sidebar-nav-link-icon'>
                    <IconCampaignDetail />
                  </span>
                  <span className='sidebar-nav-link-text'>Campaign Details</span>
                </span>
                {/* Whats app Flow */}
                {
                  (() => {
                    if (selectedCampaign.campaign_type != "Analytics" && props?.UserReducer?.view_admin_dashboard === "True") {
                      return (<>
                        <span className={activeCampaignDetailMenu === 'Add Influencers' ? "sidebar-nav-link active" : "sidebar-nav-link"} onClick={() => {
                          handleTabClick('Add Influencers', `add-influencers?planID=${plan_id}&campaignID=${selectedCampaign?.campaign_id}`
                          );
                        }}>
                          <span className='sidebar-nav-link-icon'>
                            <MaterialSymbol iconName='person_add' fontweight='200' />
                            {/* whats app flow icon */}
                          </span>
                          <span className='sidebar-nav-link-text'>Add Influencers</span>


                        </span>



                      </>
                      )
                    }

                  })()}

                {/* Whats app Flow */}
                {
                (()=>{
                  if(selectedCampaign.campaign_type != "Analytics"){
                    return(<>
                    <span className={activeCampaignDetailMenu === 'Proposal' ? "sidebar-nav-link active" : "sidebar-nav-link"} onClick={() => {handleTabClick('Proposal', `proposals?planID=${plan_id}&campaignID=${selectedCampaign?.campaign_id}`
                  );
                }}>
                  <span className='sidebar-nav-link-icon'>
                    <IconProposal />
                  </span>
                  <span className='sidebar-nav-link-text'>Proposals</span>
                  {unreadProposal.length > 0 && <span className='unread-notification-icon'></span>}
                </span>
                <span className={activeCampaignDetailMenu === 'Creatives' ? "sidebar-nav-link active" : "sidebar-nav-link"} onClick={() => handleTabClick('Creatives', `creative?planID=${plan_id}&campaignID=${selectedCampaign?.campaign_id}`
                )
                }>
                  <span className='sidebar-nav-link-icon'>
                    <IconCreative />
                  </span>
                  <span className='sidebar-nav-link-text'>Creatives</span>
                  {unreadCreative.length > 0 && <span className='unread-notification-icon'></span>}
                </span>
                { ['Paid', 'Paid + Barter'].includes(selectedCampaign.campaign_type) &&
                  <span 
                    className={activeCampaignDetailMenu === 'Payments' ? "sidebar-nav-link active" : "sidebar-nav-link"} 
                    onClick={() => handleTabClick('Payments', `payment?planID=${plan_id}&campaignID=${selectedCampaign.campaign_id}`)}
                  >
                    <span className='sidebar-nav-link-icon'>
                      <IconPayment />
                    </span>
                    <span className='sidebar-nav-link-text'>Payments</span>
                  </span>
                }
                </>
                )}

                })()}
                

                <span
                  className={activeCampaignDetailMenu === 'Analytics' ? "sidebar-nav-link active" : "sidebar-nav-link"} onClick={() => handleTabClick('Analytics', `analytics?planID=${plan_id}&campaignID=${selectedCampaign.campaign_id}`
                  )
                  }
                >
                  <span className='sidebar-nav-link-icon'>
                    <IconAnalytics />
                  </span>
                  <span className='sidebar-nav-link-text'>Analytics</span></span>
              </nav>
              <div className='outlet-container'>
                <div className='outlet'>
                  <Outlet />
                </div>
              </div>

            </div>
          )}
        </div>
      </section>
      <PlanDetailDrawer onClose={handlePlanDetailPopupClose} closeDrawer={handlePlanDetailPopupClose} onOpen={handlePlanDetailPopup} open={openPlanDetailPopup} planInfo={planInfo} selectedPlanName={planName} />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer.UserData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
    selectInfluencer: (data: any) => dispatch(SelectedCampaign(data)),
  };
};

const connectToRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaignView);

export { connectToRedux as CampaignView };
