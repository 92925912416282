import * as React from 'react';
import CommonPopUp from '../popup/common-popup';
import { LoadingButton } from '@mui/lab';

interface Props {
  children?: any;
  openUpgradeBrandModal: boolean;
  popupMinWidth?: any;
  popupMaxWidth?: any;
  onClose: () => void; // Receive onClose callback as a prop
  campaignStatusForDraft?:any;
  save_Post?: (data:any) => any;
  loading?:any;
  
}

const UpgradeBrandPopUp: React.FC<Props> = ({
  children,
  openUpgradeBrandModal,
  onClose,
  popupMinWidth,
  popupMaxWidth,
  save_Post,
  campaignStatusForDraft,
  loading
}) => {
  return (
    <CommonPopUp
      modalTitle="Upgrade to Premium:"
      modalContent=""
      openModal={openUpgradeBrandModal}
      onClose={onClose}
      className="modal-medium mx-auto"
    >
      <div className="">
        <div>
          <p>Your account currently has limited access. To unlock the full potential of our platform, upgrade to premium and access all the features, such as:</p>
        </div>
        <ul>
          <li>View unlimited influencer profiles.</li>
          <li>Execute unlimited campaigns.</li>
          <li>Send Agreement to Influencers.</li>
          <li>Get detailed campaign performance reports.</li>
        </ul>
        <div className='popup-footer' style={{ marginTop: "32px" }}>
          <div className='row'>
            <div className='col text-end'>
            {save_Post && 
              <LoadingButton
                loading={loading}
                loadingPosition="start"
                className="btn btn-outline-primary"
                variant="contained"
                onClick={() => save_Post && save_Post(campaignStatusForDraft)}
              >
                Save as draft
              </LoadingButton>
              }
              <a className=" btn btn-primary ms-2 btn-mui" href="/brand/settings/subscription">
                Upgrade Now
              </a>
             
            </div>
          </div>
        </div>
      </div>
    </CommonPopUp>
  );
};

export default UpgradeBrandPopUp;